import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    userList: [],
    allUsers:[]
}
export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {
        case Constant.USERSLIST:
            return ({
                ...state,
                userList: action.payload.data,
            })
        case Constant.ALL_USERS_LIST:
            return ({
                ...state,
                allUsers: action.payload.data,
            })

        case Constant.ADDUSER:
            data = state.userList;

            data.data.unshift(action.payload)
            return ({
                ...state,
                userList: data,
            })
        case Constant.DELETEUSER:
            data = state.userList;
            let deleteIndex = data.data.findIndex(a => a.id == action.payload)
            data.data.splice(deleteIndex, 1)
            return ({
                ...state,
                userList: data,
            })
        case Constant.UPDATE_USER:
            data = state.userList;
            let index = data.data.findIndex(a => a.id == action.payload.id)
            data.data.splice(index, 1, action.payload)

            return ({
                ...state,
                userList: data,
            })


        default:
            return state;

    }

}