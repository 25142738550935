import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    paginatedData: [],
    parents: [],
    overView: [],
    data: [],
    totalOverview: [],
    subDepartment: []

}
export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {

        case Constant.FETCHDEPARTMENTLIST:
            return ({
                ...state,
                // data: (action.payload.data.parents == undefined) ? action.payload.data.data.records : action.payload.data.records.data,
                paginatedData: action.payload.data,
                // parents: action.payload.data.parents
            })

        case Constant.FETCHALLDEPARTMENTLIST:
            return ({
                ...state,
                data: action.payload.data.data,
                // parents: action.payload.data.parents
            })
        case Constant.SUB_DEPARTMENT_FETCH:
            return ({
                ...state,
                subDepartment: action.payload.data.data,
                // parents: action.payload.data.parents
            })
        case Constant.FETCHALLPARENTDEPARTMENTLIST:
            return ({
                ...state,
                // data: action.payload.data.data,
                parents: action.payload.data.data
            })

        case Constant.FETCH_TOTAL_OVERVIEW:
            return ({
                ...state,
                totalOverview: action.payload.data.data,
            })
        case Constant.FETCHSUBDAPRTMENTOVERVIEW:
            return ({
                ...state,
                overView: action.payload.data.data,
            })

        case Constant.ADDDEPARTMENT:
            data = state.data;
            return ({
                ...state,
                data: data,
            })
        case Constant.DELETEDEPARTMENT:
            data = state.paginatedData.data;
            let temp = {};
            temp.data = data.filter(a => a.id != action.payload)
            return ({
                ...state,
                paginatedData: temp,
            })
        case Constant.UPDATEDEPARTMENT:
            data = state.paginatedData.data;
            let index = data.findIndex(a => a.id == action.payload.data.id)
            data.splice(index, 1, action.payload.data)
            temp = state.paginatedData;
            temp.data = data;

            return ({
                ...state,
                paginatedData: temp,
            })


        default:
            return state;

    }

}