import AuthReducers from './AuthReducer';

import { combineReducers } from 'redux';
import LoaderReducer from './LoaderReducer';
import dependenciesReducers from './dependenciesReducers';
import usersReducers from './usersReducers';
import industryReducers from './industryReducers';
import departmentReducers from './departmentReducers';
import categoryReducers from './categoryReducers';
import deviceReducers from './deviceReducers';
import sensorsReducer from './sensorsReducer';
import sidebarReducers from './sidebarReducers';
import LogsReducers from './LogsReducers';
import reportReducers from './reportReducers';
import groupReducers from './groupReducers';
import roleReducers from './RoleReducers';
import machineReducers from './machineReducer';
import settingReducers from './settingReducer';
import permissionReducers from './permissionReducer';
import DeviceStatus from './DeviceStatus';

export default combineReducers({
    AuthReducers,
    LoaderReducer,
    dependenciesReducers,
    usersReducers,
    industryReducers,
    departmentReducers,
    categoryReducers,
    deviceReducers,
    sensorsReducer,
    sidebarReducers,
    LogsReducers,
    reportReducers,
    groupReducers,
    roleReducers,
    machineReducers,
    settingReducers,
    permissionReducers,
    DeviceStatus

});