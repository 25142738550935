import axios from "axios";
import { BASEURL } from "./Config";
import { store } from "../Store/store";
import * as util from "../helper/Utilities";
const axiosInstance = axios.create({
  baseURL: BASEURL,
});
axiosInstance.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
    };
    const token = store.getState().AuthReducers.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      // your failure logic
    }
    return response;
  },
  function (error) {
    if (error?.response?.status == 401) {
      localStorage.clear();
      util.errorToast(error.response?.statusText);
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    }
    return Promise.reject(error.response);
  }
);
export { axiosInstance };
