const initialState = {
  sidebarShow: "responsive",
  sidebarMinimized: true,
};
export default (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "sidebarMinimized":
      return { ...state, sidebarMinimized: rest.sidebarShow };
    default:
      return state;
  }
};
