import {
  REFRESHTIMER,
  DEFAULT_DATE,
  WIDGET_DIFFERENCE,
  CATEGORIES_CONSTANTS,
  DEFAULT_MAX_RANGE_VALUE,
} from "src/helper/Config";
import { toast } from "react-toastify";
import { store } from "../Store/store";
import moment from "moment";
export let stringIsEmpty = (str) => {
  return !str || /^\s*$/.test(str);
};
export let emailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export let phoneRegex = RegExp(
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
);

export const currentDate = (date) => {
  let difference = 0;
  let currentDate = moment();
  let selectedDate = moment(date);
  difference = currentDate.diff(selectedDate, "days");
  return difference == 0 ? true : false;
};

export const campareShift = (shiftTime, created_at) => {
  let a = moment(shiftTime);
  let b = moment(created_at);
  let difference = b.diff(a, "seconds");
  return difference > 0 ? true : false;
};

export const widgetTime = (item) => {
  try {
    let difference = 0;
    let a = DEFAULT_DATE;
    if (item?.data?.length > 0) {
      let value = [...item?.data];
      value = value.sort(function (a, b) {
        return (
          new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime()
        );
      });
      let temp = value.find(
        (a) =>
          a.attribute.toLowerCase() == "energy" ||
          a.attribute.toLowerCase() == "totalizer"
      );

      if (stringIsEmpty(temp)) {
        a = moment(value[0]?.created_at);
      } else {
        a = moment(temp?.created_at);
      }
    } else if (item?.real_time_data?.length > 0) {
      let value = item?.real_time_data;
      a = moment(value[0]?.created_at);
    } else if (item?.data?.length == 0) {
      a = DEFAULT_DATE;
    } else if (item?.real_time_data?.length == 0) {
      a = DEFAULT_DATE;
    }

    let b = moment();

    difference = b.diff(a, "seconds");
    return difference > WIDGET_DIFFERENCE;
  } catch (error) {
    console.log("error", error);
    return false;
  }
};

export let numberCommaValue = (val) => {
  try {
    if (val == "-") {
      return val;
    } else {
      if (!stringIsEmpty(val)) {
        var temp = val;

        if (typeof val == "string") {
          temp = Number(val) * 1.0;
          temp = temp.toLocaleString("en-US", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          });
        } else {
          temp = val.toLocaleString("en-US", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          });
        }
        return temp;
      } else {
        return "-";
      }
    }
  } catch (error) {
    console.log("Exception", error);
    return temp;
  }
};

export const errorToast = (msg) => {
  try {
    toast.error(msg, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
    });
  } catch (e) {
    console.log("errorToast Exception", e);
  }
};

export const successToast = (obj) => {
  toast.success(obj, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
};

export const isSuperAdmin = () => {
  let roleId = store.getState().AuthReducers.role.id;
  if (roleId == 1) {
    return true;
  } else {
    return false;
  }
};

export const isSystemnAdmin = () => {
  let roleId = store.getState().AuthReducers.role.id;
  if (roleId == 2) {
    return true;
  } else {
    return false;
  }
};

export const isAdmin = () => {
  let rolename = store.getState().AuthReducers.role.title;
  if (rolename == "Admin") {
    return true;
  } else {
    return false;
  }
};

export const industryId = () => {
  let industry_id = 0;
  industry_id = store.getState().AuthReducers.authType.industry_id;
  if (!stringIsEmpty(industry_id)) {
    return industry_id;
  } else {
    return 0;
  }
};

export const categoriesMaxValue = (categoryId) => {
  let maxValue = 10000;
  try {
    let category = CATEGORIES_CONSTANTS.find(
      (a) => a.id === parseInt(categoryId)
    );
    if (category) {
      maxValue = category.maxValue;
    }
    return maxValue;
  } catch (error) {
    return maxValue;
  }
};

export const timeDisplay = (item) => {
  let a = DEFAULT_DATE;
  try {
    if (item?.data.length > 0) {
      let value = [...item?.data];
      // console.log("value", value);
      let temp = value.find(
        (a) =>
          a.attribute.toLowerCase() == "energy" ||
          a.attribute.toLowerCase() == "totalizer"
      );
      // console.log("temp", temp);
      // value = value.sort(function (a, b) { return new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime() });
      a = moment(temp?.created_at);
    }

    return a?.format("MMMM Do YYYY, h:mm:ss a");
  } catch (error) {
    return DEFAULT_DATE;
  }
};

export const getCategorySensorMaxRange = (sensorList, category_id, type) => {
  let max_Range = 0;

  let data = sensorList.filter(
    (x) => x.category_id == category_id && x.type == type
  );

  for (let i = 0; i < data?.length; i++) {
    max_Range += data[i].max_range;
  }

  return max_Range == 0 ? DEFAULT_MAX_RANGE_VALUE : String(max_Range);
};

export const getCategoryAndDepartmentSensorMaxRange = (
  sensorList,
  category_id,
  type,
  department_id
) => {
  let max_Range = 0;

  let data = sensorList.filter(
    (x) =>
      x.category_id == category_id &&
      x.type == type &&
      x.department?.parent_id == department_id
  );

  for (let i = 0; i < data?.length; i++) {
    max_Range += data[i].max_range;
  }

  return max_Range == 0 ? DEFAULT_MAX_RANGE_VALUE : String(max_Range);
};
export const getCategoryAndSubDepartmentSensorMaxRange = (
  sensorList,
  category_id,
  type,
  department_id
) => {
  let max_Range = 0;

  let data = sensorList.filter(
    (x) =>
      x.category_id == category_id &&
      x.type == type &&
      x.department_id == department_id
  );

  for (let i = 0; i < data?.length; i++) {
    max_Range += data[i].max_range;
  }

  return max_Range == 0 ? DEFAULT_MAX_RANGE_VALUE : String(max_Range);
};

export const dateTime = (dateTimeString, shiftTime) => {
  // Regex pattern to match date in YYYY-MM-DD format
  var datePattern = /^\d{4}-\d{2}-\d{2}$/;
  // Regex pattern to match date and time in YYYY-MM-DD HH:mm:ss format
  var dateTimePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;

  // Check if the input string matches the date pattern
  if (datePattern.test(dateTimeString)) {
    // If only date is provided, add default time (00:00:00)
    return dateTimeString + shiftTime;
  } else if (dateTimePattern.test(dateTimeString)) {
    // If date and time are provided, return as is
    return dateTimeString;
  } else {
    // Invalid format
    return "Invalid format";
  }
};
