import React, { useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";

import "./scss/style.scss";
import { useDispatch, useSelector } from "react-redux";
import { AuthAction } from "./Store/Actions/AuthAction";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/Login/Login"));
const ForgotPassword = React.lazy(() => import("./views/Login/ForgotPassword"));
// const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  const dispatch = useDispatch();
  const { token, detail } = useSelector((state) => state.AuthReducers);
  useEffect(async () => {
    if (token) await dispatch(AuthAction.getUserProfile());
  }, [0]);
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      // Check if user is logged in, if yes, prevent going back
      if (token) {
        return;
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [token]);
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/forgot-password"
            name="Forgot Password "
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
