import { Constant } from "../Actions/Constant";
const INITIAL_STATE = {
  data: [],
};
export default (state = INITIAL_STATE, action) => {
  let data;
  switch (action.type) {
    case Constant.FETCH_PERMISSION_LIST:
      return {
        ...state,
        data: action.payload.data,
      };
    case Constant.ADD_PERMISSION:
      data = state.data;
      data.data.unshift(action.payload);
      return {
        ...state,
        data: data,
      };
    case Constant.DELETE_PERMISSION:
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.filter((item) => item.id !== action.payload),
        },
      };

    case Constant.UPDATE_PERMISSION:
      const updatedData = state.data.data.map((item) =>
        item.id === action.payload.data.id ? action.payload.data : item
      );

      return {
        ...state,
        data: {
          ...state.data,
          data: updatedData,
        },
      };

    default:
      return state;
  }
};
