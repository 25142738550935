import { Constant } from '../Actions/Constant'
const INITIAL_STATE = {
    data: [],
}
export default (state = INITIAL_STATE, action) => {
    let data;
    switch (action.type) {
        case Constant.DEVICES_STATUS:
            return ({
                ...state,
                data: action.payload,
            })

        default:
            return state;

    }

}