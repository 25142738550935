// system ip
export const BASEURL = "https://cms.industryiot.pk/cms/public/api/";
// export const BASEURL = "http://scada-backend.test/api/";
export const REFRESHTIMER = 15000;
export const REFRESHTIMER_1000 = 15000;
export const WIDGET_DIFFERENCE = 300;
export const DEFAULT_DATE = "2000-05-16 00:05:46";

export const CATEGORIES_CONSTANT = (param) => {
  if (!param) {
    return CATEGORIES_CONSTANTS;
  } else {
    CATEGORIES_CONSTANTS = param;
  }
};

export let CATEGORIES_CONSTANTS = [
  {
    id: 1,
    name: "Electircal",
    maxValue: 10000,
  },
  {
    id: 2,
    name: "Water",
    maxValue: 250,
  },
  {
    id: 3,
    name: "Gas",
    maxValue: 5000,
  },
  {
    id: 4,
    name: "Steam",
    maxValue: 50,
  },
];

export const DEFAULT_MAX_RANGE_VALUE = 0.001
